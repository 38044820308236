import { useState, useEffect } from 'react'
import '../scss/home.scss'
import '../scss/about.scss'
import AnimatedLetters from './AnimatedLetters'
import Loader from 'react-loaders'
import TagCloud from 'TagCloud'

export default function About() {
  const [letterClass, setLetterClass] = useState('text-animate')
  const myTags = [
    'JavaScript',
    'CSS',
    'HTML',
    'C',
    'React',
    'Python',
    'git',
    'Node.js',
    'OpenCV',
    'Angular',
    'React Native',
    'Open Layers',
  ]

  TagCloud('.tag-cloud', myTags, {
    radius: 300,

    maxSpeed: 'fast',
    initSpeed: 'fast',

    direction: 135,

    keep: true,
  })

  useEffect(() => {
    const id = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 2000)

    window.scrollTo(0, 0)
    document.documentElement.style.overflow = 'hidden'

    return () => window.clearTimeout(id)
  }, [])

  return (
    <>
      <div className="container about-page">
        <span className="tags top-tags">&lt;body&gt;</span>

        <span className="tags bottom-tags-about">
          &lt;/body&gt;
          <br />
          <span className="bottom-tag-html">&lt;/html&gt;</span>
        </span>
        <div className="tag-cloud-container">
          <div className="text-zone">
            <h1>
              <AnimatedLetters
                strArray={[
                  'S',
                  'k',
                  'i',
                  'l',
                  'l',
                  's',
                  ' ',
                  'a',
                  'n',
                  'd',
                  ' ',
                  'e',
                  'x',
                  'p',
                  'e',
                  'r',
                  'i',
                  'e',
                  'n',
                  'c',
                  'e',
                ]}
                index={15}
                letterClass={letterClass}
              />
            </h1>
            <p>
              I have experience developing applications using several JavaScript
              technologies such as React, React Native, Angular, NodeJS, and
              Express. I'm currently pursuing my Solutions Architect Associate
              Certificate in AWS to become proficient in quickly emerging cloud
              technologies. I'm seeking a role that needs fullstack work so that
              I can learn more about the application development process from
              end to end.
            </p>
          </div>
          <div className="tag-cloud"></div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

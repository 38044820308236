import React from 'react'
import Typewriter from 'typewriter-effect/dist/core'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'
import '../../scss/home.scss'

function TypewriterEffect() {
  var element = document.getElementById('typewriter')
  var typewriter = new Typewriter(element, {
    loop: true,
    delay: 40,
    deleteSpeed: 10,
  })

  typewriter.state.elements.container = element

  typewriter
    .typeString(
      '<span style="color: #aa5099">import</span> <span style="color: rgb(3, 172, 240)">{ frontEndDev }</span> <span style="color: #aa5099">from</span> <span style="color: #976037">"./USF/comp-sci"</span>;<br><br><span style="color: #0000ff">frontEndDev</span>.<span style="color: rgb(12, 200, 30)">graduate</span><span style="color: rgb(3, 172, 240)">({</span><br>'
    )
    .pasteString('•••••')
    .typeString(
      '<span style="color: rgb(3, 172, 240)">name:</span> <span style="color: #976037">\'Adrian Grillo\'</span>,<br>'
    )
    .pasteString('•••••')
    .typeString(
      '<span style="color: rgb(3, 172, 240)">skills:</span> <span style="color: #ead84e">[</span><span style="color: #976037">\'Angular\'</span>, <span style="color: #976037">\'React\'</span>, <span style="color: #976037">\'React Native\'</span><span style="color: #ead84e">]</span>,<br>'
    )
    .pasteString('•••••')
    .typeString(
      '<span style="color: rgb(3, 172, 240)">date:</span> <span style="color: #976037">\'Spring 23\\\'\'</span>,<br>'
    )
    .pasteString('•••••')
    .typeString('<span style="color: rgb(3, 172, 240)">isHireable:')
    .pauseFor(500)
    .deleteChars(11)
    .typeString('<span style="color: rgb(3, 172, 240)">isStrongCandidate:')
    .typeString('</span> <span style="color: #0000ff">true</span><br>')
    .typeString('<span style="color: rgb(3, 172, 240)">})</span>')

    .pauseFor(1000)
    .deleteChars(10)
    .pauseFor(500)
    .start()

  return <div id="typewriter"></div>
}

function ParticleEffect() {
  const particlesInit = (main) => {
    loadFull(main)
  }

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        autoPlay: true,
        fullScreen: false,
        background: {
          color: {
            value: '#181818',
          },
          position: '50% 50%',
          repeat: 'no-repeat',
          size: 'cover',
          opacity: 1,
        },
        backgroundMask: {
          composite: 'destination-out',
          cover: {
            color: {
              value: '#fff',
            },
            opacity: 1,
          },
          enable: false,
        },
        detectRetina: true,
        duration: 0,
        fpsLimit: 120,
        interactivity: {
          detectsOn: 'window',
          events: {
            onClick: {
              enable: true,
              mode: 'push',
            },
            onDiv: {
              enable: false,
              type: 'circle',
            },
            onHover: {
              enable: true,
              mode: 'grab',
              parallax: {
                enable: true,
                force: 60,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            attract: {
              distance: 200,
              duration: 0.4,
              easing: 'ease-out-quad',
              factor: 1,
              maxSpeed: 50,
              speed: 1,
            },
            bounce: {
              distance: 200,
            },
            bubble: {
              distance: 400,
              duration: 2,
              mix: false,
              opacity: 0.8,
              size: 40,
              divs: {
                distance: 200,
                duration: 0.4,
                mix: false,
              },
            },
            connect: {
              distance: 80,
              links: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 400,
              links: {
                blink: false,
                consent: false,
                opacity: 1,
              },
            },
            light: {
              area: {
                gradient: {
                  start: {
                    value: '#ffffff',
                  },
                  stop: {
                    value: '#000000',
                  },
                },
                radius: 1000,
              },
              shadow: {
                color: {
                  value: '#000000',
                },
                length: 2000,
              },
            },
            push: {
              default: true,
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
              factor: 100,
              speed: 1,
              maxSpeed: 50,
              easing: 'ease-out-quad',
              divs: {
                distance: 200,
                duration: 0.4,
                factor: 100,
                speed: 1,
                maxSpeed: 50,
                easing: 'ease-out-quad',
              },
            },
            slow: {
              factor: 3,
              radius: 200,
            },
            trail: {
              delay: 1,
              pauseOnStop: false,
              quantity: 1,
            },
          },
        },
        motion: {
          disable: false,
          reduce: {
            factor: 4,
            value: true,
          },
        },
        particles: {
          bounce: {
            horizontal: {
              random: {
                enable: false,
                minimumValue: 0.1,
              },
              value: 1,
            },
            vertical: {
              random: {
                enable: false,
                minimumValue: 0.1,
              },
              value: 1,
            },
          },
          collisions: {
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
            },
            enable: false,
            mode: 'bounce',
            overlap: {
              enable: true,
              retries: 0,
            },
          },
          color: {
            value: '#ffffff',
            animation: {
              h: {
                count: 0,
                enable: false,
                offset: 0,
                speed: 1,
                sync: true,
              },
              s: {
                count: 0,
                enable: false,
                offset: 0,
                speed: 1,
                sync: true,
              },
              l: {
                count: 0,
                enable: false,
                offset: 0,
                speed: 1,
                sync: true,
              },
            },
          },
          destroy: {
            mode: 'none',
            split: {
              count: 1,
              factor: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 3,
              },
              rate: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: {
                  min: 4,
                  max: 9,
                },
              },
              sizeOffset: true,
            },
          },
          life: {
            count: 0,
            delay: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              sync: false,
            },
            duration: {
              random: {
                enable: false,
                minimumValue: 0.0001,
              },
              value: 0,
              sync: false,
            },
          },
          links: {
            blink: false,
            color: {
              value: '#ffffff',
            },
            consent: false,
            distance: 150,
            enable: true,
            frequency: 1,
            opacity: 0.4,
            shadow: {
              blur: 5,
              color: {
                value: '#000',
              },
              enable: false,
            },
            triangles: {
              enable: false,
              frequency: 1,
            },
            width: 1,
            warp: false,
          },
          move: {
            angle: {
              offset: 0,
              value: 90,
            },
            attract: {
              distance: 200,
              enable: false,
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            center: {
              x: 50,
              y: 50,
              radius: 0,
            },
            decay: 0,
            direction: 'none',
            drift: 0,
            enable: true,
            gravity: {
              acceleration: 9.81,
              enable: false,
              inverse: false,
              maxSpeed: 50,
            },
            path: {
              clamp: true,
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
              },
              enable: false,
            },
            outModes: {
              default: 'out',
              bottom: 'out',
              left: 'out',
              right: 'out',
              top: 'out',
            },
            random: false,
            size: false,
            speed: 2,
            spin: {
              acceleration: 0,
              enable: false,
            },
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: '#000000',
              },
            },
            vibrate: false,
            warp: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000,
            },
            limit: 0,
            value: 100,
          },
          opacity: {
            random: {
              enable: true,
              minimumValue: 0.1,
            },
            value: {
              min: 0.1,
              max: 0.5,
            },
            animation: {
              count: 0,
              enable: true,
              speed: 3,
              sync: false,
              destroy: 'none',
              startValue: 'random',
              minimumValue: 0.1,
            },
          },
          orbit: {
            animation: {
              count: 0,
              enable: false,
              speed: 1,
              sync: false,
            },
            enable: false,
            opacity: 1,
            rotation: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 45,
            },
            width: 1,
          },
          reduceDuplicates: false,
          repulse: {
            random: {
              enable: false,
              minimumValue: 0,
            },
            value: 0,
            enabled: false,
            distance: 1,
            duration: 1,
            factor: 1,
            speed: 1,
          },
          roll: {
            darken: {
              enable: false,
              value: 0,
            },
            enable: false,
            enlighten: {
              enable: false,
              value: 0,
            },
            mode: 'vertical',
            speed: 25,
          },
          rotate: {
            random: {
              enable: false,
              minimumValue: 0,
            },
            value: 0,
            animation: {
              enable: false,
              speed: 0,
              sync: false,
            },
            direction: 'clockwise',
            path: false,
          },
          shadow: {
            blur: 0,
            color: {
              value: '#000',
            },
            enable: false,
            offset: {
              x: 0,
              y: 0,
            },
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: {
              enable: true,
              minimumValue: 1,
            },
            value: {
              min: 0.1,
              max: 10,
            },
            animation: {
              count: 0,
              enable: true,
              speed: 20,
              sync: false,
              destroy: 'none',
              startValue: 'random',
              minimumValue: 0.1,
            },
          },
          stroke: {
            width: 0,
          },
          tilt: {
            random: {
              enable: false,
              minimumValue: 0,
            },
            value: 0,
            animation: {
              enable: false,
              speed: 0,
              sync: false,
            },
            direction: 'clockwise',
            enable: false,
          },
          twinkle: {
            lines: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
            particles: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
          },
          wobble: {
            distance: 5,
            enable: false,
            speed: 50,
          },
          zIndex: {
            random: {
              enable: false,
              minimumValue: 0,
            },
            value: 0,
            opacityRate: 1,
            sizeRate: 1,
            velocityRate: 1,
          },
        },
        pauseOnBlur: true,
        pauseOnOutsideViewport: true,
        zLayers: 100,
      }}
    />
  )
}

export default function Header() {
  const [particles, setParticles] = React.useState(false)

  // Only load particle effect after typewriter has been initialized
  // If not loaded, typewriter will only appear sometimes
  React.useEffect(() => {
    const interval = setTimeout(setParticles(true), 0)

    return clearTimeout(interval)
  }, [])

  return (
    <div className="export">
      <TypewriterEffect />
      {particles ? <ParticleEffect /> : null}
    </div>
  )
}

import { useEffect } from 'react'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'
import Home from './components/home/Home'
import Nav from './components/Nav'
import About from './components/About'
import Projects from './components/Projects.js'
import Contact from './components/Contact'

const AppRoutes = () => {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/projects', element: <Projects /> },
    { path: '/contact', element: <Contact /> },
  ])

  return routes
}

export default function App() {
  useEffect(() => {
    console.log(
      'Thanks for being curious and checking out my portfolio! I hope you like it.'
    )
  }, [])

  /* @ 1:06:00 minutes in video */

  return (
    <Router>
      <Nav />
      <AppRoutes />
    </Router>
  )
}

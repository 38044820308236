import React from 'react'
import Loader from 'react-loaders'
import '../../scss/home.scss'
import AnimatedLetters from '../AnimatedLetters'
import { Link } from 'react-router-dom'

export default function Main() {
  const [letterClass, setLetterClass] = React.useState('text-animate')
  const nameArray = ['I', "'", 'm', ' ', 'A', 'd', 'r', 'i', 'a', 'n', ',']
  const jobArray = [
    'f',
    'r',
    'o',
    'n',
    't',
    'e',
    'n',
    'd',
    ' ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]

  React.useEffect(() => {
    const id = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 2000)

    window.scrollTo(0, 0)
    document.documentElement.style.overflow = 'hidden'

    return () => window.clearTimeout(id)
  }, [])

  return (
    <>
      <div className="container home-container">
        <span className="tags top-tags">&lt;body&gt;</span>

        <span className="tags bottom-tags">
          &lt;/body&gt;
          <br />
          <span className="bottom-tag-html">&lt;/html&gt;</span>
        </span>

        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _13`}>i</span>
            <span className={`${letterClass} _14`}>,</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              index={15}
            />{' '}
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              index={25}
            />
          </h1>
          <div className="sub-header">
            <h2>React / React Native / Angular</h2>
            <Link to="/contact">
              <button className="flat-button">CONTACT ME</button>
            </Link>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

import { useEffect, useState, useRef } from 'react'
import '../../src/scss/contact.scss'
import Loader from 'react-loaders'
import AnimatedLetters from './AnimatedLetters'
import emailjs from '@emailjs/browser'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

export default function Contact() {
  const [letterClass, setLetterClass] = useState('text-animate')
  const form = useRef()

  useEffect(() => {
    const id = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 2000)

    window.scrollTo(0, 0)
    document.documentElement.style.overflow = 'hidden'

    return () => window.clearTimeout(id)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_hl2kcrn',
        'template_0plcdkc',
        form.current,
        'GGBwLjfYUFNDliEbD'
      )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <div className="contact-page">
      <span className="tags top-tags">&lt;body&gt;</span>

      <span className="tags bottom-tags-about">
        &lt;/body&gt;
        <br />
        <span className="bottom-tag-html">&lt;/html&gt;</span>
      </span>
      <div className="text-zone-contact">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
            index={15}
          />
        </h1>
        <p>
          I'm interested in internship opportunities that allow for a
          collaborative environment. I'm seeking to practice skills outside of
          my expertise to make myself a more versatile developer so I can make
          broader contributions to large projects.
        </p>
        <div className="contact-form">
          <form ref={form} onSubmit={sendEmail}>
            <ul>
              <li className="half">
                <input type="text" name="name" placeholder="Name" required />
              </li>
              <li className="half">
                <input type="email" name="email" placeholder="Email" required />
              </li>
              <li>
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                />
              </li>
              <li>
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
              </li>
              <li>
                <input type="submit" className="flat-button" value="SEND" />
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div className="info-map">
        Adrian Grillo
        <br />
        United States
        <br />
        Tampa, Fl <br />
        <br />
        <span>grillo.adrian13@gmail.com</span>
      </div>
      <div className="map-wrap">
        <MapContainer center={[27.964157, -82.452606]} zoom={13}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[27.964157, -82.452606]}>
            <Popup>Hello world!</Popup>
          </Marker>
        </MapContainer>
      </div>
      <Loader type="pacman" />
    </div>
  )
}

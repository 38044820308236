import { NavLink } from 'react-router-dom'
import { FaUserAstronaut } from 'react-icons/fa'
import { BsCodeSlash } from 'react-icons/bs'
import {
  AiOutlineMail,
  AiFillGithub,
  AiOutlineLinkedin,
  AiOutlineHome,
} from 'react-icons/ai'
import '../../src/scss/nav.scss'

export default function Nav() {
  return (
    <div className="nav-bar">
      <nav>
        <NavLink exact="true" activeclassname="active" to="/">
          <AiOutlineHome color="#4d4d4e" />
        </NavLink>
        <NavLink activeclassname="active" className="about-link" to="/about">
          <FaUserAstronaut color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="my-work-link"
          to="/projects"
        >
          <BsCodeSlash color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
        >
          <AiOutlineMail color="#4d4d4e" />
        </NavLink>
      </nav>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/adrian-grillo/"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineLinkedin color="#4d4d4e" />
          </a>
        </li>
        <li>
          <a
            href="https://github.com/AdrianGrillo"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillGithub color="#4d4d4e" />
          </a>
        </li>
      </ul>
    </div>
  )
}

import React from 'react'
import * as THREE from 'three'
import '../scss/projects.scss'
var glsl = require('glslify')

export default function Projects() {
  // Shaders
  const vertexShader = glsl(`
        uniform sampler2D uTexture;
        uniform vec2 uOffset;
        varying vec2 vUv;
        
        #define M_PI 3.14159265
        
        vec3 deformationCurve(vec3 position, vec2 uv, vec2 offset) {
        position.x = position.x + (sin(uv.y * M_PI) * offset.x);
        position.y = position.y + (sin(uv.x * M_PI) * offset.y);
        return position;
        }
        
        void main() {
        vUv = uv;
        vec3 newPosition = deformationCurve(position, uv, uOffset);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
        }
    `)

  const fragmentShader = glsl(`
        uniform sampler2D uTexture;
        uniform float uAlpha;
        uniform vec2 uOffset;
        varying vec2 vUv;
        
        vec3 rgbShift(sampler2D textureImage, vec2 uv, vec2 offset) {
        float r = texture2D(textureImage, uv + offset).r;
        vec2 gb = texture2D(textureImage, uv).gb;
        return vec3(r,gb);
        }
        
        void main() {
        vec3 color = rgbShift(uTexture,vUv,uOffset);
        gl_FragColor = vec4(color, uAlpha);
        }
    `)

  let scrollable = React.useRef(null)

  React.useEffect(() => {
    init()
    new EffectCanvas()

    document.documentElement.style.overflowY = 'visible'
  })

  let current = 0
  let target = 0
  let ease = 0.1

  // Linear interpolation used for smooth scrolling and image offset uniform adjustment
  function lerp(start, end, t) {
    return start * (1 - t) + end * t
  }

  function init() {
    document.body.style.height = `${
      scrollable.current.getBoundingClientRect().height
    }px`
  }

  function smoothScroll() {
    target = window.scrollY
    current = lerp(current, target, ease)
    scrollable.current.style.transform = `translate3d(0, ${-current}px, 0)`
  }

  class EffectCanvas {
    constructor() {
      this.container = document.querySelector('main')
      this.images = [...document.querySelectorAll('img')]
      this.meshItems = []
      this.setupCamera()
      this.createMeshItems()
      this.render()
    }

    get viewport() {
      let width = window.innerWidth
      let height = window.innerHeight
      let aspectRatio = width / height
      return {
        width,
        height,
        aspectRatio,
      }
    }

    setupCamera() {
      window.addEventListener('resize', this.onWindowResize.bind(this))

      this.scene = new THREE.Scene()

      let perspective = 1000
      const fov =
        (180 * (2 * Math.atan(window.innerHeight / 2 / perspective))) / Math.PI
      this.camera = new THREE.PerspectiveCamera(
        fov,
        this.viewport.aspectRatio,
        1,
        1000
      )
      this.camera.position.set(0, 0, perspective)

      this.renderer = new THREE.WebGL1Renderer({
        antialias: true,
        alpha: true,
      })
      this.renderer.setSize(this.viewport.width, this.viewport.height)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.container.appendChild(this.renderer.domElement)
    }

    onWindowResize() {
      init()
      this.camera.aspect = this.viewport.aspectRatio
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(this.viewport.width, this.viewport.height)
    }

    createMeshItems() {
      this.images.forEach((img) => {
        let meshItem = new MeshItem(img, this.scene)
        this.meshItems.push(meshItem)
      })
    }

    render() {
      smoothScroll()
      for (let i = 0; i < this.meshItems.length; i++) {
        this.meshItems[i].render()
      }
      this.renderer.render(this.scene, this.camera)
      requestAnimationFrame(this.render.bind(this))
    }
  }

  class MeshItem {
    constructor(element, scene) {
      this.element = element
      this.scene = scene
      this.offset = new THREE.Vector2(0, 0)
      this.sizes = new THREE.Vector2(0, 0)
      this.createMesh()
    }

    getDimensions() {
      const { width, height, top, left } = this.element.getBoundingClientRect()
      this.sizes.set(width, height)
      this.offset.set(
        left - window.innerWidth / 2 + width / 2,
        -top + window.innerHeight / 2 - height / 2
      )
    }

    createMesh() {
      this.geometry = new THREE.PlaneBufferGeometry(1, 1, 30, 30)
      this.imageTexture = new THREE.TextureLoader().load(this.element.src)
      this.uniforms = {
        uTexture: { value: this.imageTexture },
        uOffset: { value: new THREE.Vector2(0, 0) },
        uAlpha: { value: 1 },
      }
      this.material = new THREE.ShaderMaterial({
        uniforms: this.uniforms,
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
      })
      this.mesh = new THREE.Mesh(this.geometry, this.material)
      this.getDimensions()
      this.mesh.position.set(this.offset.x, this.offset.y, 0)
      this.mesh.scale.set(this.sizes.x, this.sizes.y, 0)

      this.scene.add(this.mesh)
    }

    render() {
      this.getDimensions()
      this.mesh.position.set(this.offset.x, this.offset.y, 0)
      this.mesh.scale.set(this.sizes.x, this.sizes.y, 0)
      this.uniforms.uOffset.value.set(0, -(target - current) * 0.0002)
    }
  }

  return (
    <main className="projects-page">
      <div
        ref={scrollable}
        className="scrollable"
        style={{ height: 'auto', width: '100vw' }}
      >
        <div className="projects-container">
          <div className="image-container">
            <img
              className="main-img"
              src="https://i.ibb.co/kGPMpLW/Screen-Shot-2020-11-03-at-10-47-47-PM.png"
              alt=""
            />
            <img
              className="main-img"
              id="component-tree"
              src="https://i.ibb.co/BG9QW5g/Screenshot-5.png"
              alt=""
            />
            <div className="text-container">
              <h1>Giphy Clone</h1>
              <h2>ReactJS</h2>
              <h3>
                <a
                  href="https://github.com/AdrianGrillo/giphy-clone"
                  alt="github link"
                  style={{ color: '#ffd700' }}
                >
                  Github Repository
                </a>
              </h3>

              <h3>Functionality includes:</h3>

              <h4>
                • Successful integration of a third party API into a web page{' '}
                <br />
                <br />
                • Utilization of SASS to add responsive design elements <br />
                <br />
                • Full searchbar functionality <br />
                <br />• Reusable React components
              </h4>

              <hr color="#181818" />

              <h3>Other Cool Things:</h3>

              <h4>
                • Full component tree available in the repository README <br />
                <br />
                • All custom CSS (No Bootstrap or Styled Components) <br />
                <br />• Code Splitting
              </h4>

              <hr color="#181818" />

              <h3>
                Site is live{' '}
                <a
                  href="https://thirsty-galileo-e135d3.netlify.app/"
                  style={{ color: '#ffd700' }}
                >
                  here
                </a>
              </h3>

              <h3>Deployed using Netlify</h3>
            </div>
          </div>

          <div className="image-container">
            <div className="flex-container">
              <div className="images-grid">
                <img
                  className="main-img"
                  src="https://i.ibb.co/kqRy8yb/EA883-C38-4-CEE-45-B4-B2-BC-B26-BF9-AF3-B24.png"
                  alt=""
                />
                <img
                  className="main-img"
                  src="https://i.ibb.co/gSgQR5h/60-D61-F92-6736-4267-8440-D88283-FBB79-A.png"
                  alt=""
                />
                <img
                  className="main-img"
                  src="https://i.ibb.co/tXy89Td/C7-A13-AB3-A352-45-BC-9586-F0-FCC46840-AA.png"
                  alt=""
                />
              </div>
              <div className="text-container">
                <h1>Path Tracker Mobile App</h1>
                <h2>React Native</h2>
                <h3>
                  <a
                    href="https://github.com/AdrianGrillo/workout-app-client"
                    alt="github link"
                    style={{ color: '#ffd700' }}
                  >
                    Github Repository
                  </a>
                </h3>

                <h3>Functionality includes:</h3>

                <h4>
                  • Full authentication system with MongoDB, Context, and
                  password encryption.
                  <br />
                  <br />
                  • Walk around in the real world and save a track to your
                  account to view later. <br />
                  <br />
                  • The app will draw a polyline of the path on the map using
                  the location data from the users phone. <br />
                  <br />• React navigation to control app flow and transfer data
                  between different screens.
                </h4>

                <hr color="#181818" />

                <div className="horizontal-text">
                  <h3 style={{ marginBottom: 25 }}>Other Cool Things:</h3>

                  <h4>
                    • Custom reusable hooks use the phones location data and
                    save a users track to their account. <br />
                    <br />
                    • Custom context components used in place of redux for data
                    flow and tracking. <br />
                    <br />• Built using react-native maps,
                    react-native-gesture-handler, and react-native-elements.
                  </h4>

                  <hr
                    style={{ width: '35vw', marginTop: 25 }}
                    color="#181818"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
